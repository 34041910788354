@include u-keyframes(fadeOutZoomBig) {
  0% {
    @include u-transform(scale(1) translateZ(0));
  }
  40% {
    @include u-transform(scale(1.25));
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    @include u-transform(scale(0));
  }
}

@mixin fadeOutZoomBig($duration: 1s, $delay: 0s, $fill-mode: forwards) {
  @include u-animation(fadeOutZoomBig $duration $delay $fill-mode);
}
