@include u-keyframes(fadeOut) {
  0% {
    opacity: 1;
    @include u-transform(translateZ(0));
  }
  100% {
    opacity: 0;
  }
}

@mixin fadeOut($duration: 1s, $delay: 0s, $fill-mode: forwards) {
  @include u-animation(fadeOut $duration $delay $fill-mode);
}
