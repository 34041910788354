/*------------------------------------------------------------------
[Table of contents] by christophhaid.com

  Mixins
  Breakpoints&media queries
  
1.  COMMON STYLES
2.  BUTTONS
3.  HEADER
4.  FOOTER
5.  HERO
6.  STARTPAGE
7.  LISTVIEW
  filter panel
  sortingPanel
  productlist
  pagination
  Subnavigation accordion
  Topproducts
  Helpbox
  
8.  Multiselect Syle  
9.  DetailVIEW
10. NumberCount
11. BASKET
12. CART VIEW 
12. POPUP

-------------------------------------------------------------------*/
/* Mixins */
@import 'bourbon'; // http://bourbon.io/
@import "saffron/saffron";

$grey: #505455;
$blue: #008bd2;
$red: #BC2F35;


/* Breakpoints&media queries */
$sm:     576px;   
$md:     768px;     
$lg:     992px; 
$xl:     1200px;  

// media queries
@mixin MQ($canvas) {
  @if $canvas == sm {
   @media only screen and (min-width: $sm) { @content; } 
  }
  @else if $canvas == md {
   @media only screen and (min-width: $md) { @content; } 
  }
  @else if $canvas == lg {
   @media only screen and (min-width: $lg) { @content; } 
  }
    @else if $canvas == xl {
   @media only screen and (min-width: $xl) { @content; } 
  }
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}


/************************* COMMON STYLES *************************/
html{
  font-size: 11px;

  @include MQ(lg) {
  font-size: 13px;
  }
}



a {
    font-weight: 400;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

button, input, optgroup, select, textarea {
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
}

.container {
    margin-left: auto;
    margin-right: auto;
    width: 1400px;
    -webkit-transition: 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

@media only screen and (max-width: 1050px) and (min-width: 800px){
  .container {
    margin-left: auto;
    margin-right: auto;
    width: 95%;
}
}

@media only screen and (max-width: 800px) and (min-width: 0px){
.container {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}
}

.table-responsive {
  @include MQ(xl) {
  display: table;
  }
}

/* BUTTONS */
.btn-default{
  background-color: #fff;
  color: #000;
  font-size: .9em;
  height: 37px;
  line-height: 1.4em;
  padding: 10px 10px;
  text-transform: uppercase;
  border: 1px solid #ccc;
  text-decoration: none;
  @include border-top-radius(5px);
  @include border-right-radius(5px);
  @include border-bottom-radius(5px);
  @include border-left-radius(5px);
   &:hover {
    color: #292b2c;
    background-color: #e6e6e6;
    border-color: #adadad;
  }
}

  .btn-blue{
    background: $blue;
    color: #fff;
    text-decoration: none;
  @include border-top-radius(5px);
  @include border-right-radius(5px);
  @include border-bottom-radius(5px);
  @include border-left-radius(5px);
  padding: 10px 10px;
  cursor: pointer;
  &:hover {
    color: #fff;
    background-color: #025aa5;
    border-color: #01549b;
  }
  }

  .btn-gray{
    background: #666;
    color: #fff;
    text-decoration: none;
  @include border-top-radius(5px);
  @include border-right-radius(5px);
  @include border-bottom-radius(5px);
  @include border-left-radius(5px);
  padding: 10px 10px;
  cursor: pointer;
  &:hover {
    color: #fff;
    background-color: #999;
    border-color: #999;
  }
  }

/************************* HEADER *************************/
header{
	background: #fff;

  .header{
    position: relative;
    padding-bottom: 20px;
  }
  .logowrap{
    text-align:center;
    margin-bottom: 20px;
    @include MQ(md) {
      text-align:left;
      margin-bottom: 0px;
    }
  }

	.logo img{
    width: 100%;
		max-width: 360px;
    
    @include MQ(sm) {
      max-width: 250px;
    }
	}

	.row{
		min-height: 115px;
	}

  .icons-top{
    position: relative;
    width: 100%;
    text-align: center; 
    margin-bottom: 20px;  
    
    @include MQ(md) {
      position: absolute;
      right: 0;
      top: 0;
      width: 600px;
      text-align: right;
    }
    .btn-top{
      background: $grey;
      color: #fff;
      border: 0px;
      border-top: 5px solid $blue;
      border-radius:0px;
      text-transform: uppercase;
      transition: border-top 0.5s ease;
      vertical-align: top;
      &:hover{
        border-top: 9px solid $blue;
        transition: border-top 0.5s ease;
      } 
    }
  }

	.icons-last{
		text-align: center;

    @include MQ(lg) {
      text-align: right;
    }
    .topnavi{
      padding: 0px;
    }

    .topnavi a {
      color: $grey;
      transition: color 0.2s;
      &:hover{
        color: $blue;
        text-decoration: none;
      }
    }
    .topnavi li{
      display: inline-block;
      text-transform: uppercase;
      margin-right: 10px;
      &:last-child{
        margin-right: 0px;
      }
    }
	}

  nav{
    background: $blue;
    color: #fff;

    .mainnavi-wrap{
      float: none;
      @include MQ(lg) {
      float: left;
      }
    }
    
    ul.mainnavi{
      padding: 0px;
      margin: 0px;
      
      @include MQ(lg) {
      display: inline-block;
      }
    }

    .search-field{
      display: inline-block;
      vertical-align: top;
      padding: 19px;
      .bild{
        height: 28px;
        width: 28px;
        margin-right: 5px;
      }
      .feld{
        border: 1px solid $grey;
        padding: 5px;
        color: $blue;
        width: 150px;

        @include MQ(lg) {
        width: 250px;
        }
      }
      input{
        float: left;
      }
    }

    .basket-wrap{
      float: none;

      @include MQ(lg) {
      float: right;
      margin-right: 30px;
      }
    }
    

    .cd-cart {
      position:relative;
      color: #fff;
      text-transform: uppercase;
      display: block;
      padding: 10px;

      @include MQ(lg) {
        padding-top: 22px;
        }
      
      span.text {
        vertical-align: top;
        line-height: 28px;
        text-transform: uppercase;
      }

      &:before{
        display: inline-block;
        content: ' ';
        background-image: url('../images/basket.svg');
        background-size: 28px 28px;
        height: 28px;
        width: 28px;
      }

      span.num {
        /* number of items added to the cart */
        position: absolute;
        height: 18px;
        width: 18px;
        right: -10px;
        top: 18px;
        line-height: 18px;
        background-color: white;
        color: $blue;
        font-size: 1rem;
        font-weight: bold;
        text-align: center;
        border-radius: 50%;
        
        /* scale it down if no products have been added to the cart */
        @include transform(scale(0));
        @include transition(transform .2s 0s);
      }

      &.items-added span.num {
        /* counter visible when a product is added to the cart */
        @include transform(scale(1));
      }

    }


    .mainnavi li{
      list-style: none;
      display: block;
      

      @include MQ(md) {
        width: 30%;
        display: inline-block;
        }

      @include MQ(lg) {
        width: auto;
        display: inline-block;
        margin-left: -3px;
        }

      }

    .mainnavi a{
      padding: 10px 10px;
      display: block;
      color: #fff;
      line-height: 1rem;
      transition: background 0.5s ease;

      @include MQ(lg) {
        padding: 20px;
        border-right: 1px solid rgba(255, 255, 255, 0.3) ;
        box-shadow: 1px 0px 0px #004cad;
        }

      &:hover{
        text-decoration: none;
        background: rgba(0,0,0,0.1);
        transition: background 0.5s ease;
      }
      span {
        vertical-align: top;
        line-height: 28px;
        text-transform: uppercase;
      }
      &:before{
        display: inline-block;
        content: ' ';
        height: 28px;
        width: 28px;
        background-image: url('../images/products.svg');
        background-size: 28px 28px;
        background-position: fixed;

      }
    }

    .mainnavi a.products:before{
        background-image: url('../images/products.svg');
      }
    .mainnavi a.manufacturer:before{
        background-image: url('../images/manufacturer.svg');
      }
    .mainnavi a.deals:before{
        background-image: url('../images/deals.svg');
      }
    .mainnavi a.search:before{
        background-image: url('../images/search.svg');
      }
  }
}


body.checkout{
  .mainnavi-wrap{
    display: none; 
  }
  .basket-wrap{
     margin: 0 auto;
  }
  footer .container{
    display: none;
  }
}

/************************* FOOTER *************************/
footer{
  background:$grey;
  border-top: 10px solid $blue;
  padding: 20px 0;
  color: #fff;

  .col-lg-4{
    margin-bottom: 40px;
  }
  @include MQ(lg) {
    .col-lg-4{
    &:nth-child(1){
    padding-right: 40px;
    border-right: 1px solid #333333 ;
    box-shadow: 1px 0px 0px rgba(255, 255, 255, 0.2);
    }
    &:nth-child(2){
    padding: 0 40px;
    border-right: 1px solid #333333 ;
    box-shadow: 1px 0px 0px rgba(255, 255, 255, 0.2);
    }
    &:nth-child(3){
    padding-left: 40px;
    }
    }
  }

  h6{
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1.1rem;
    }

  ul {
  margin: 0;
  padding: 0;
  }
  ul.dashed {
    list-style-type: none;
  }
  ul.dashed > li {
    text-indent: -5px;
  }
  ul.dashed > li:before {
    content: "-";
    margin-right: 5px;
  }  

  .footerblocks{
    margin-bottom: 30px;
    &:after{
    content: '';
    display: block;
    clear: both;
    }
    .icon{
    width: 40px;
    float: left;
    }
    .text{
    width: calc(100% - 40px);
    float: left;
    }
  }

  .newsletter {
  overflow: hidden;
  height: 40px;
  margin: 0 auto;
  position: relative;
  input {
    width: 100%;
    border: 1px solid white;
    border-radius: 5px;
    height: 40px;
    padding: 10px 0 10px 15px;   
    text-transform: uppercase;
    outline: none;
    @include transition(border .2s ease);
    &:focus {
      border: 1px solid #999;
      + button {
        background-color: $blue;
      }
    }
  }
  button {
    position: absolute;
    z-index: 999;
    right: 2px;
    top: 2px;
    height: 36px;
    border: none;
    background-color: #666;
    @include transition(background-color .3s ease, width .3s ease);
    color: white;
    padding: 0;
    margin: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    text-transform: uppercase;
    width: 100px;
    text-align: center;
    cursor: pointer;
    @inlude transform(translateZ(0));
    &:hover {
      width: 140px;
      background-color: $blue;
        }
      }   
    }

  .images{
    margin-top: 15px;
    img{
      margin-right:15px;
      margin-bottom: 10px;
    }
  }

  .adress{
   margin-bottom: 10px;
   ul{
    list-style: none;
    margin: 0 0 0 10px;
    padding:0px;
   }
   &:after{
    content: '';
    display: block;
    clear: both;
    }
    .icon{
    width: 20px;
    float: left;
    }
    .text{
    width: calc(100% - 40px);
    float: left;
    }
  }

  .orderbox{
    background: $blue;
    position: relative;
    padding: 6px 5%;
    display: flex;
    align-items: center;
    .title{
      text-transform: uppercase;
      margin-right: 10px;
      font-size:  1.154rem;
    }
    @include MQ(lg) {
      position: absolute;
      padding: 6px 5%;
      width: 90%;
      top: -20px;
      left: -20px;
      font-size:  1.077rem;
    }
  }

  .links{
    display: flex;
    font-size: 0.7692rem;
    margin-top: 40px;
    
    @include MQ(lg) {
      margin-top: 80px;
    }
    h6{
      text-decoration: uppercase;
      font-size: 0.7692rem;
    }
    a{
      color: #fff;
    }
    .col{
      width: 33.333%
    }
  }
}

.footer_bottom{
    background: #000;
    color: #fff;
    padding: 10px 0;
  }


/************************* HERO *************************/
.hero{
    height: 30em;
    background: url(../images/fotolia_94463900.jpg) no-repeat center;
    background-size: cover;
    position: relative;
    @include MQ(lg) {
    height: 45em;
    }

    .claim{
    color:#fff;
    text-shadow: 1px 1px 1px #000;
    padding: 10% 7em 0 0em;
    position: relative;
    width: 100%;
    text-transform: uppercase;
    font-size:  1.6rem;
    
    @include MQ(lg) {
    width: 60%;
    float: left;
    font-size:  3.6rem;
    padding: 16% 7em 0 0em;
    }

    p{
      margin-bottom: 0;
    }
    .big{
      font-size:  2.6rem;
      @include MQ(lg) {
      font-size:  4.6rem;
      }
    }
    }

    .box{
      width: 100%;
      padding: 5% 0em 0 0em;

      @include MQ(lg) {
      width: 40%;
      float: right;
      padding: 16% 7em 0 0em;
      }

      .title{
        background: $blue;
        padding: 10px;
        color: #fff;
        font-size: 1.692rem;
        text-transform: uppercase;
        font-weight: 200;
        line-height: 109%;
      }
      .content{
         background: white;
         color: $grey;
         font-size: 1.231rem;
         padding: 10px;
      }
    }
}


/************************* STARTPAGE *************************/
.select-area{
  padding: 60px 0;
  background: #cee3f7;
}

.category{
  overflow: hidden;
  margin-bottom: 20px;
  position:relative;
}

.category .inner {
     bottom: 0;
    padding: 10px 20px 15px;
    position: absolute;
    width: 100%;
    z-index: 200;
    background: rgba(255,255,255,0.4);

    @include transition(all 0.5s ease 0s);

    .title {
    color: $grey;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }
}

.category:hover .inner {
  padding: 20px 20px 25px;
  background: rgba(0,139,210,0.4);
  .title {
    color: #fff;
  }
}

.category .image img {
    width: 100%;

    @include transform(scale(1.0));
    @include transition(transform .5s 0s);
}

.category:hover .image img {
    @include transform(scale(1.1));
    @include transition(transform .5s 0s);
}

.whybox{
  text-align: center;
  margin-bottom: 20px;
  img{
    margin-bottom: 10px;
  }
  h5{
    color: $blue;
    margin: 0px;
    font-size: 1rem;
  }
  a{
    display: block;
    &:before{
    content: "\f178";
    font-family: "FontAwesome";
    margin-right: 7px;

    @include transition(all 0.5s ease 0s);

    }
    &:hover{
      text-decoration: none;
      @include transition(all 0.5s ease 0s);
      &:before{
      margin-right: 5px;
      }
    }
  }
}

.teaser-area{
  padding: 30px 0;
  background: #fff;
  .teaserbox{
    margin: 30px 0;
  }
}


.footable-filtering-search{
  .fooicon-search::before {
      content: "\f002";
  }
  .fooicon-remove::before {
    content: "\f00d";
}

  .fooicon {
      position: relative;
      top: 1px;
      display: inline-block;
      font-family:'FontAwesome' !important;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }
  .dropdown-toggle{
    display: none
  }
  .input-group-btn.open .dropdown-menu{
    display: block;
  }

}

/************************* StoryDetail *************************/
.storydetail-content{
  padding: 30px 15px;
  h1{
    color: $blue;
  }
  h2{
    font-size: 1.5rem;
    margin-top: 2rem;
  }
  .content{
    margin-top: 40px;
  }
  p, ul {
    line-height: 2rem;
  }
  ul li{
    list-style: none;
    &::before {
    content: "\f178";
    font-family: "FontAwesome";
    margin-right: 7px;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    }
  }
 }

 .storylist-content{
  padding: 30px 15px;
  h1{
    color: $blue;
  }
  h2{
    font-size: 1.5rem;
    margin-top: 2rem;
  }
  .list-post {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid #ebebeb;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;

    .post-thumb {
    display: block;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    max-width: 50%;
      img {
      display: block;
      max-width: 100%;
      }
    }

    .content {
    display: block;
    width: 100%;
    padding-left: 30px;
    vertical-align: top;
      .post-meta {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      font-size: 11px;
      }
      .post-title {
      max-width: 80%;
      margin-top: 17px;
      margin-bottom: 15px;
      font-size: 24px;
      line-height: 1.25;
      }
    }
  }
}

.img-responsive{
  width: 100%;
  height: auto;
}

.owl-carousel .item {
    height: 10rem;
    background: #4DC7A0;
    padding: 1rem;
    color: #fff;
}

/************************* LISTVIEW *************************/
.breadcrumbs{
  background: rgba(0,140,210,0.2);
  padding: 5px 0;
  .divider{
    color: $blue;
    padding: 0 10px;
  }
}

.listing-content{
  padding: 30px 15px;
  .row.float{
  display: block;
  }
  &:after {
    clear: both;
    display: table;
    content: " ";
  }
}

.content-aside {
  float: none;
  -webkit-box-shadow: inset -1px 0 0 #fff;
  box-shadow: inset -1px 0 0 #fff;
  right: -1px;
  background: #fff;
  border-top: 1px solid transparent;
  @include MQ(md) {
  float: left;
  }

  .headline .title{
    line-height: initial;
    font-size: 2.154rem;
    vertical-align: top;
    margin-left: 7px;
    color: $blue;
  }
  section h3 {
  font-weight: bold;
  font-size: 1.167em;
  }
}

.content-center {
    float: none;
    margin-bottom: -25px;
    @include MQ(md) {
    float: right;
    }
}

.icon-product {
    width: 34px;
    height: 34px;
    display: inline-block;
    background: url(../images/products.svg);
}


.listing-content{
  h1{
    color: $blue;
    text-transform: uppercase;
    font-weight: 300;
  }

  .category-banners{
    height: 200px;
    overflow: hidden;
    margin-top: 10px;
    img{
      position: relative;
      top: 50%;
      transform: perspective(1px) translateY(-50%);
      width: 100%;
    }
  }

/*filter panel */
.filter-panel {
  background: $blue;
  color: #fff;
  padding: 0 1.3em 20px 1.3em;
  a {
  text-decoration: none;
  }
  .btn-default{
    width: 100%;
    text-align: left;
  }
  .dropdown-menu {
    z-index: 1001;
    -webkit-box-shadow: 0 1px 3px rgba(5,5,5,0.1);
    box-shadow: 0 1px 3px rgba(5,5,5,0.1);
    border: 1px solid rgba(0,0,0,0.05);
    font-size: 1em;
    border-radius: 0;
    margin-left: 15px;
  }
}

/* sortingPanel */
.sorting-panel {
  padding: 1.3em 0 1.3em;
  border-bottom: 2px solid #eaeaea;
  a {
  text-decoration: none;
  }
  .count_Product{
    background: #008bd2;
    border-radius: 100%;
    color: #fff;
    width: 50px;
    height: 50px;
    display: inline-block;
    line-height: 50px;
    text-align: center;
    margin-right: 10px;
  }
  .dropdown-menu {
    z-index: 1001;
    -webkit-box-shadow: 0 1px 3px rgba(5,5,5,0.1);
    box-shadow: 0 1px 3px rgba(5,5,5,0.1);
    border: 1px solid rgba(0,0,0,0.05);
    font-size: 1em;
    border-radius: 0;
  }
  .perpage-select .dropdown-menu {
    min-width: 53px;
  }
}

/* productlist */
.products-list{
  padding: 30px 0;
  border-bottom: 2px solid #eaeaea;
  .subtitle{
    color: $grey;
    font-size: 0.7692rem;
    margin-top: -5px;
    margin-bottom: 10px;
  }
  .price.old {
    text-decoration: line-through;
    font-size: 1.3rem;
    @include MQ(lg) {
    font-size: 1.5rem;
    }

  }
  .price.new {
    font-size: 1.3rem;
    color: $blue;
    @include MQ(lg) {
    font-size: 1.8rem;
    }
  }
  .btn{
    margin: 5px 5px 0px 0;
  }
}

.single-slide-product{
  overflow: hidden;
  position: relative;
  border: 2px solid #eaeaea;
  img{
    width: 100%;
    height: auto;
  }
  .product-label {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 10;
    text-align: right;
    span {
    display: block;
    min-width: 70px;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 16px;
    padding: 5px 8px;
    background: $red;
    color: #fff;
    text-align: center;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
    -webkit-transition: all 0.4s linear;
    -moz-transition: all 0.4s linear;
    -o-transition: all 0.4s linear;
    transition: all 0.4s linear;
    /* position: absolute; */
    top: 0;
    left: 0;
    display: inline-block;
    margin-left: 3px 
    }
    span.featured {
    left: auto;
    right: 0;
    background: $blue;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    }
  }
}

.twoimages{
  &:hover .slide-product-image-two {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
  &:hover .slide-product-image-one {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  .slide-product-image-one {
    -webkit-transition: 0.6s;
    transition: 0.6s
  }
  .slide-product-image-two {
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    width: 100%;
    -webkit-transition: 0.6s;
    transition: 0.6s
    }
}

}

/* pagination */
.pagination-panel{
  padding: 30px 0;
}

.pagination {
  margin: 0;
   a {
    display: block;
    float: left;
    margin-right: 11px;
    border: 1px solid #ccc;
    width: 36px;
    height: 36px;
    line-height: 34px;
    color: $blue;
    text-align: center;
    font-size: .9em;
    margin-left: 0;
    margin-right: 11px
    }
}
@media (max-width: 1199px) {
  body.responsive .pagination a {
    margin-right: 3px;
    width: 36px
  }
  body.responsive .filters-panel .view-mode a {
    margin-right: 5px
  }
}
.pagination a:hover, .pagination a.active {
  text-decoration: none;
  border-color: $blue;
  color: #fff;
  background: $blue;
}

/* Subnavigation accordion*/
.accordion-simple {
  text-align: left
}
.accordion-simple .panel-heading {
  padding: 0
}
.accordion-simple .panel-heading a {
  display: block;
  position: relative;
  font-size: 1.077rem;
  line-height: 1em;
  font-weight: 300;
  text-transform: uppercase;
  padding: 8px 5px 8px 5px;
  margin-top: 5px;
  background: #eaeaea;
}
.accordion-simple .panel-heading a:hover {
  text-decoration: none
}
.accordion-simple .panel+.panel {
  box-shadow: none;
  -webkit-box-shadow: none;
  border-radius: 0;
  margin: 0
}
.accordion-simple .panel:first-child .panel-heading a {
  border-top: none
}
.accordion-simple .arrow-down, .accordion-simple .arrow-up {
  display: block;
  position: absolute;
  font-size: 1.54em;
  top: 50%;
  right: 10px;
  margin-top: -0.35em
}
.accordion-simple .arrow-down, .accordion-simple .collapsed .arrow-up {
  display: none
}
.accordion-simple .collapsed .arrow-down {
  display: inline
}
.accordion-simple .panel-body {
  border-top: 0 !important;
  padding: 0 0 10px
}

.accordion-simple-two {
  text-align: left;
  .panel{
    border-bottom: 1px dotted #cacaca;
  }
}
.accordion-simple-two .panel-heading {
  padding: 0
}
.accordion-simple-two .panel-heading a {
  display: block;
  position: relative;
  font-size: 1rem;
  line-height: 1em;
  text-transform: normal;
  color: $blue;
  font-weight: 400;
  padding: 5px 18px 5px 15px;
  background: none;
}

.accordion-simple-two .panel-heading a.collapsed{
  color: $grey;
}

.accordion-simple-two .panel-heading a:hover {
  text-decoration: none
}
.accordion-simple-two .panel+.panel {
  box-shadow: none;
  -webkit-box-shadow: none;
  border-radius: 0;
  margin: 0
}
.accordion-simple-two .panel:first-child .panel-heading a {
  border-top: none
}
.accordion-simple-two .arrow-down, .accordion-simple-two .arrow-up {
  display: block;
  position: absolute;
  font-size: 1.54em;
  top: 50%;
  left: 0;
  margin-top: -0.35em
}
.accordion-simple-two .arrow-down, .accordion-simple-two .collapsed .arrow-up {
  display: none
}
.accordion-simple-two .collapsed .arrow-down {
  display: inline
}
.accordion-simple-two .panel-body {
  border-top: 0 !important;
  padding: 0;
}

.simple-list{
  list-style: none;
  padding-left: 15px;
  padding-top: 5px;
  a{
    color: $grey;
  }
}

/*Topproducts*/
.topproduct{
  margin-bottom: 20px;
  .image{
    overflow: hidden;
    height: 200px;
    display: block;
  }
  img{
    position: relative;
    top: 50%;
    transform: perspective(1px) translateY(-50%);
    width: 100%;
  }
  .title{
    font-size: 1rem;
    color: $blue;
    margin: 5px 0 0 0;
  }
    .price.old {
    text-decoration: line-through;
  }
  .price.new {
    color: $blue;
    margin-left: 10px;
  }
}

/*Helpbox*/
.leftbox{
  margin-top: 60px;
  .teaser{
    background: $blue;
    color: #fff;
    padding: 5px;
    font-size: 1.077rem;
    text-transform: uppercase;
  }
  p{
    margin: 10px 0;
  }
  a{
    display: block;
    margin: 3px 0;
    &:before{
    content: "\f178";
    font-family: "FontAwesome";
    margin-right: 7px;

    @include transition(all 0.5s ease 0s);

    }
    &:hover{
      text-decoration: none;
      @include transition(all 0.5s ease 0s);
      &:before{
      margin-right: 5px;
      }
    }
  }
}

.callback{
  border: 1px solid #eaeaea;
  .content{
    padding:10px 20px;
    label {
    display: inline-block;
    margin-bottom: 0rem;
    margin-top: .5rem;
    color: $blue;
    }
  }
}

.selections-panel{
  padding: 20px;
  .title{
    color: $blue;
    font-size: 1.5rem;
  }
}


/************************* Multiselect Syle *************************/
.multiselect-native-select{
  .btn-group{
    width: 100%;
  }
  .multiselect-container>li>a>label {
    margin: 0;
    height: 100%;
    cursor: pointer;
    font-weight: 400;
    padding: 6px 20px 3px 20px;
}
}

.filter-panel .selectbox > div{
  padding-top:20px;
}

.filter-panel .selectbox select {
    border: 1px solid #e9e9e9;
    padding: 8px 15px;
  -moz-appearance: none;
    -o-appearance:none;
    -ms-appearance: none;
  appearance: none;
  width:100%;
  background-color:#fff;
}

.filter-panel .selectbox .btn-group {
    width: 100%;
}

.filter-panel .selectbox .btn-group button {
    width: 100%;
  -webkit-border-radius: 2px;
-moz-border-radius: 2px;
border-radius: 2px;
  border: 1px solid #e9e9e9;
padding: 8px 20px 7px;
   text-align: left;
}

.filter-panel .selectbox .open > .dropdown-menu {
    display: block;
    width: 100%;
}

.filter-panel .selectbox .btn-group .dropdown-menu > .active > a, .filter .selectbox .btn-group .dropdown-menu > .active > a:focus, .filter .selectbox .btn-group .dropdown-menu > .active > a:hover {
    background-color: $blue;
    color: #fff;
    outline: 0 none;
    text-decoration: none;
}

.filter-panel .selectbox .btn-group button span {
  float:left;
}

.filter-panel .selectbox .btn-group button i {
    float: right;
    color:#00513d;
    font-size:20px;
    padding-top: -4px;
}

.filter-panel .selectbox .h3 {
    color: #00513d;
    font-size: 22px;
    margin-bottom: 10px;
    padding-left: 20px;
}

.filter-panel .selectbox .dropdown-toggle::after{
  display: none;
}

.filter-panel .selectbox.dropdown, .filter-panel .selectbox .dropup{
position:relative}


.filter-panel .selectbox .dropdown-toggle:focus{
outline:0}


.filter-panel .selectbox .dropdown-menu{
position:absolute;
top:100%;
left:0;
z-index:1000;
min-width:160px;
padding:5px 0;
margin:2px 0 0;
font-size:14px;
text-align:left;
list-style:none;
background-color:#fff;
-webkit-background-clip:padding-box;
background-clip:padding-box;
border:1px solid #ccc;
border:1px solid rgba(0,0,0,.15);
border-radius:4px;
-webkit-box-shadow:0 6px 12px rgba(0,0,0,.175);
box-shadow:0 6px 12px rgba(0,0,0,.175)}


.filter-panel .selectbox .dropdown-menu.pull-right{
right:0;
left:auto}


.filter-panel .selectbox .dropdown-menu .divider{
height:1px;
margin:9px 0;
overflow:hidden;
background-color:#e5e5e5}


.filter-panel .selectbox .dropdown-menu>li>a{
display:block;
padding:3px 20px;
clear:both;
font-weight:400;
line-height:1.42857143;
color:#333;
white-space:nowrap}


.filter-panel .selectbox .dropdown-menu>li>a:focus,.dropdown-menu>li>a:hover{
color:#262626;
text-decoration:none;
background-color:#f5f5f5}


.filter-panel .selectbox .dropdown-menu>.active>a,.dropdown-menu>.active>a:focus,.dropdown-menu>.active>a:hover{
color:#fff;
text-decoration:none;
outline:0}


.filter-panel .selectbox .dropdown-menu>.disabled>a,.dropdown-menu>.disabled>a:focus,.dropdown-menu>.disabled>a:hover{
color:#777}


.filter .selectbox .dropdown-menu>.disabled>a:focus, .filter-panel .selectbox .dropdown-menu>.disabled>a:hover{
text-decoration:none;
cursor:not-allowed;
background-color:transparent;
background-image:none;
filter:progid:DXImageTransform.Microsoft.gradient(enabled=false)}


.filter-panel-panel .selectbox .open>.dropdown-menu{
display:block}


.filter-panel .selectbox .open>a{
outline:0}


.filter-panel .selectbox .dropdown-menu-right{
right:0;
left:auto}


.filter-panel .selectbox .dropdown-menu-left{
right:auto;
left:0}


.filter-panel .selectbox .dropdown-header{
display:block;
padding:3px 20px;
font-size:12px;
line-height:1.42857143;
color:#777;
white-space:nowrap}


.filter-panel .selectbox .dropdown-backdrop{
position:fixed;
top:0;
right:0;
bottom:0;
left:0;
z-index:990
}
/**/

.filter-panel .filterbutton{
  margin-top: 15px;
  a{
    background:rgba(0,0,0,0.3);
    cursor: pointer;
    line-height: 25px;
    text-transform: uppercase;
    &:hover{
      background:rgba(0,0,0,0.8);
    }
  }
}

/************************* DetailVIEW *************************/
.backtolist{
  padding-bottom:10px;
  color: $grey;
  border-bottom: 2px solid rgba(80, 84, 86, 0.1);
}

.products_detail{
  margin-top: 30px;
  .subtitle{
    color: $grey;
    font-size: 0.7692rem;
    margin-top: -5px;
    margin-bottom: 10px;
  }
  .price.old {
    text-decoration: line-through;
    font-size: 1.5rem;
  }
  .price.new {
    font-size: 1.8rem;
    color: $blue;
  }
  .product-img{
    position: relative;
    .product-label {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 10;
    text-align: right;
      span {
      display: block;
      min-width: 70px;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 16px;
      padding: 5px 8px;
      background: $red;
      color: #fff;
      text-align: center;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      border-radius: 4px;
      -webkit-transition: all 0.4s linear;
      -moz-transition: all 0.4s linear;
      -o-transition: all 0.4s linear;
      transition: all 0.4s linear;
      /* position: absolute; */
      top: 0;
      left: 0;
      display: inline-block;
      margin-left: 3px 
      }
      span.featured {
      left: auto;
      right: 0;
      background: $blue;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      -o-transition: all 0.3s linear;
      transition: all 0.3s linear;
      }
    }
  }

  img{
    width: 100%;
    height: auto;
    padding: 5px;
    border: 2px solid rgba(80, 84, 86, 0.1);
  }
  h1{
    font-size: 1.431rem;
  }
  h3{
    font-size: 0.7692rem;
  }
  .tabs_content{
    margin-top: 30px;
    .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #464a4c;
    background-color: #fff;
    border-color: #C9DFF4 #C9DFF4 #fff;
    }
    .tab-content{
    border: 1px solid #C9DFF4;
    border-top: 0px;
    margin-top: -1px;
    padding: 20px 20px 40px 20px;
    position: relative;
    .productquestion{
      position: absolute;
      bottom: 0px;
      right: -1px;
      height: 22px;
      a{
        padding: 5px 20px;
        border: 1px solid #C9DFF4;
        text-decoration: none;
        color:$blue;
      }
    }
    }
  }
}

.table.picetable{
  tr{
    cursor: pointer;
    @include MQ(md) {
        cursor: inherit;
    }
  }
  .fooicon{
    color: $blue;
    opacity: 1;
  }
  .footable-last-visible{
    text-align: center;
  }
  .wishlist{
    font-size: 0.8rem;
    text-align: center;
    a{
      color: #000;
    }
  }
}


.customer_buy{
  padding: 60px 0 20px 0;
  h3{
    color: $blue;
    font-size: 1.538rem;
    border-bottom: 2px solid rgba(80, 84, 86, 0.1);
  }
}


/************************* NumberCount *************************/
.quantity {
  position: relative;
  width: 80px;

  input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button{
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number]{
    -moz-appearance: textfield;
  }

  input {
  width: 45px;
  height: 30px;
  line-height: 1.65;
  font-size: 13px;
  float: left;
  display: block;
  padding: 0;
  margin: 0;
  padding-left: 10px;
  border: 1px solid #eee;
  }
  input:focus {
  outline: 0;
  }
  .quantity-nav {
  float: left;
  position: relative;
  height: 30px;
  .quantity-button {
    position: relative;
    cursor: pointer;
    border-left: 1px solid #eee;
    width: 20px;
    height: 15px;
    text-align: center;
    color: #333;
    font-size: 13px;
    line-height: 15px;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
      .quantity-up {
      position: absolute;
      height: 50%;
      top: 0;
      border-bottom: 1px solid #eee;
      }
      .quantity-down {
      position: absolute;
      bottom: -1px;
      height: 50%;
      }
    }
  }
}


/************************* BASKET *************************/
.basket-wrap{
  position: relative;
  cursor: pointer;
}

.mini_basket{
    position: absolute;
    top: 70px;
    right:0px;
    background: #FFF;
    padding: 15px;
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s ease .2s;
    color:#000;
    
    z-index: 1000;
    -webkit-box-shadow: -2px 0px 16px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: -2px 0px 16px -1px rgba(0,0,0,0.75);
    box-shadow: -2px 0px 16px -1px rgba(0,0,0,0.75);

    @include MQ(md) {
        min-width: 400px;
    }

    &::before{
    content: '';
    border: 10px solid transparent;
    z-index: 1001;
    text-transform: none;
    font-size: .9em;
    line-height: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    position: absolute;
    opacity: 1;
    right: 30px;
    top: -9px;
    border-top-width: 0;
    border-bottom-color: #fff;
    }
    &.visible{
      padding: 15px;
      opacity: 1;
      visibility: visible;
    }
    .header{
      color: $blue;
      border-bottom: 2px solid rgba(80, 84, 86, 0.1);
      padding-bottom: 10px;
    }
    .products{
      padding: 10px 0;
    }
    .basket_footer{
      text-align: right;
      border-top: 2px solid rgba(80, 84, 86, 0.1);
      padding-top: 10px;
      .buttons{
        padding: 10px 0;
      }
    }
    p{
      font-size: 11px;
      display:block;
      margin:0;
      padding: 0;
      line-height: 20px;
    }
  }

/************************* CART VIEW *************************/
.cart{
  padding-top: 60px;
  padding-bottom: 60px;
  h1, h3, h6{
      color: $blue;
  }
  .carttable{
    margin-top: 40px;
    border-bottom: 2px solid #C9DFF4;
    th {
    border-top: 0px solid #eceeef;
    }
    img{
      max-width: 150px;
      width: 100%;
    }
    h6{
      color: $blue;
      text-transform: uppercase;
    }
    td.text-center{
      text-align: center;
      font-size: 1.5rem;
      i{
        cursor: pointer;
      }
    }
  }
  .alert{
    margin-top: 20px;
    span{
      display: table-cell;
      vertical-align: middle;
    }
    span:nth-child(2){
      padding-left: 10px;
    }
  }

    .billingadress{
    padding: 20px 0;
    border-bottom: 2px solid #C9DFF4;
    .alert{
    color: $blue;
  }
    .delivery_block{
      margin: 10px 0;
      ul{
        list-style: none;
        padding-left: 0px;
      }
      .w50{
        width: 49.5%;
        background: #999;
        margin-top: 5px;
      }
    }
    .form-check span{
      font-size: 1.2rem;
      color: $blue;
    }
  }

  .delivery{
    padding: 40px 0;
    border-bottom: 2px solid #C9DFF4;
    .alert{
    color: $blue;
  }
    .delivery_block{
      margin: 10px 0;
      ul{
        list-style: none;
        padding-left: 0px;
      }
      .w50{
        width: 49.5%;
        background: #999;
        margin-top: 5px;
      }
    }
    .form-check span{
      font-size: 1.2rem;
      color: $blue;
    }
  }

  .payment{
    padding: 40px 0;
    .payment_methods{
      margin-top: 30px;
    }
    .display-table .radio-wrapper, .display-table .checkbox-wrapper {
    display: table;
    box-sizing: border-box;
    width: 100%;
    padding: 1.14286em;
    position: relative;

    }
    .content-box__row{
      border: 1px solid #eaeaea;
      margin-bottom: 10px;
    }
    .radio__input, .checkbox__input {
    padding-right: 0.75em;
    white-space: nowrap;
    }
    .input-checkbox, .input-radio {
    width: 18px;
    height: 18px;
    box-shadow: 0 0 0 0 #2a9dcc inset;
    transition: all 0.2s ease-in-out;
    position: relative;
    cursor: pointer;
    vertical-align: -4px;
    outline: 0;
    border: 1px solid;
    }
    .display-table .radio__input, .display-table .checkbox__input {
    display: table-cell;
  }
  .display-table .radio__label, .display-table .checkbox__label {
    display: table-cell;
    width: 100%;
  }
  .display-table .radio__label__primary {
    display: table-cell;
    width: 100%;
  }
  .radio__label__accessory {
    padding-left: 0.75em;
    white-space: nowrap;
    text-align: right;
    display: table-cell;
  }
  }

   .summery{
    padding: 20px 0;
    .adressblock{
      margin: 10px 0;
      ul{
        list-style: none;
        padding-left: 0px;
      }
      .w50{
        width: 49.5%;
        background: #999;
        margin-top: 5px;
      }
    }
    .form-check span{
      font-size: 1.2rem;
      color: $blue;
    }
  }

  .coupon{
    padding: 40px 0;
    border-bottom: 2px solid #C9DFF4;
    .form-inline{
      margin-top: 20px;
    }
  }

  .loginform{
    padding: 30px 0;
    h1{
      color: $blue;
      padding-bottom: 20px;
    }
  }

  .regform{
    padding: 40px 0;
    border-top: 2px solid #C9DFF4;
  }

  .cartteaser-box{
    background: $blue;
    color: #fff;
    margin-top: 60px;
    article{
      border-bottom: 1px solid blue;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      box-shadow: 0px 1px 0px #004cad;
      padding: 20px;
      .title{
        font-size: 1.231rem;
      }
      p{
        font-size: 0.9231rem;
      }
    }
  }

  .price-check{
    padding: 20px;
    .min_order{
      border-bottom: 2px solid #C9DFF4;
      padding-bottom: 10px;
      font-size: 0.8462rem;
      .bgdark{
        background: #eaeaea;
        padding: 6px 15px;
        margin-bottom: 5px;
      }
    }
    .price_ges{
      font-size: 1.231rem;
      color: $blue;
    }
    .agb{
      margin-top: 20px;
    }
  }

  .cart_helpbox{
    background: $blue;
    color: #fff;
    border-radius: 5px;
    margin-top: 40px;
    .col-md-3{
      border-right: 1px solid rgba(255, 255, 255, 0.3);
      box-shadow: 1px 0px 0px #004cad;
      display: flex !important;
  flex-direction: column;
  justify-content: center;
    }
    .col-md-3:last-child{
      border-right: none;
      box-shadow: none;
    }
    .p-4{
      font-size: 1.077rem;
      .tel{
        font-size:  1.538rem;
      }
      p{
        font-size: 1rem;
      }
    }
  }
}

.cd-breadcrumb{
  padding: 0.5em 1em;
  margin: 1em auto;
  background-color: $blue;
  border-radius: .25em;
  display: inline-block;


  li {
    display: inline-block;
    float: left;
    margin: 0.5em 0;

    em{
      font-style: normal;
    }

    &::after {
      /* this is the separator between items */
      display: inline-block;
      content: '//';
      margin: 0 .9em;
      color: rgba(255,255,255, 0.3);
    }

    &:last-of-type::after {
      /* hide separator after the last item */
      display: none;
    }
  }
  
  li > * {
    /* single step */
    display: inline-block;
    font-size: 1.231rem;
    color: rgba(255,255,255, 0.3);
  }

  li.current > * {
    /* selected step */
    color: #fff;
  }

  .no-touch & a:hover {
    /* steps already visited */
    color: #96c03d;
  }

  @include MQ(M) {
    padding: 0 1.2em;

    li {
      margin: 1.2em 0;

      &::after {
        margin: 0 1em;
      }
    }

    li > * {
      font-size: 1.6rem;
    }
  }
}




/************************* POPUP *************************/
.pad40 {
    padding: 60px 40px;
}
.white-popup-block {
    text-align: left;
    max-width: 1000px;
    margin: 40px auto;
    position: relative;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 10px 100px 0 rgba(0, 0, 0, 0.3);
    h1{
    font-family: Raleway;
    font-size: 1.875rem;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    text-align: left;
    color: #000000;
    text-transform: uppercase
    }
    .btn{
      width: 100%;
      background: $grey;
      border-color: $grey;
    }
    
}

.greenbg{
  background: $blue;
  color: #fff;
  text-align: center;
  i {
  font-size: 6rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  text-align: center;
  opacity: 0.3;
  }
  h3{
  font-family: Raleway;
  font-size: 1.875rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  text-align: center;
  color: #ffffff;
  margin: 20px;
  }
}
.animation {
  animation-duration: 2s;
  animation-timing-function: ease-in-out;

  &--infinite {
    animation-iteration-count: infinite;
  }

  &--up-down {
    animation-name: upDown;
  }

}

@keyframes upDown {
  0% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
.mfp-zoom-out {
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.3s ease-in-out; 
    transform: scale(1.3); 
  }
  &.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out;
  }
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: scale(1); 
    }
    &.mfp-bg {
      opacity: 0.5;
    }
  }
  &.mfp-removing {
    
    .mfp-with-anim {
      transform: scale(1.3); 
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }
    
  }
  
}



#cookie-bar {background:$blue; height:auto; line-height:24px; color:#eeeeee; text-align:center; padding:3px 0;}
#cookie-bar.fixed {position:fixed; top:0; left:0; width:100%;}
#cookie-bar.fixed.bottom {bottom:0; top:auto;}
#cookie-bar p {margin:0; padding:0;}
#cookie-bar a {color:#ffffff; display:inline-block; border-radius:3px; text-decoration:none; padding:0 6px; margin-left:8px;}
#cookie-bar .cb-enable {background:#007700;}
#cookie-bar .cb-enable:hover {background:#009900;}
#cookie-bar .cb-disable {background:#990000;}
#cookie-bar .cb-disable:hover {background:#bb0000;}
#cookie-bar .cb-policy {background:#0033bb;}
#cookie-bar .cb-policy:hover {background:#0055dd;}
