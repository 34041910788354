@include u-keyframes(poof) {
  0% {
    opacity: 1;
    @include u-transform(scale(1));
    -webkit-filter: blur(0);
  }

  100% {
    opacity: 0;
    @include u-transform(scale(2));
    -webkit-filter: blur(40px);
  }
}

@mixin poof($duration: 0.85s, $delay: 0s, $fill-mode: forwards) {
  @include u-animation(poof $duration $delay $fill-mode);
}
