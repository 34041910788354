$saf-prefixes: ("webkit", "moz", "o", "ms", "spec") !default;
$saf-translateY-distance: 1.334em !default;
$saf-translateX-distance: 1.334em !default;
$saf-drop-rise-distance: 10em !default;
$saf-shake-distance: 0.55em !default;
$saf-teeter-angle: 10deg !default;
$saf-quiver-angle: 2deg !default;
$saf-float-bob-distance: 0.25em !default;
$saf-float-bob-angle: 1deg !default;
$saf-sway-angle: 3deg !default;
$saf-turn-angle: 225deg !default;
$saf-pulsate-scale: 1.1 !default;
$saf-stretch-compress-exageration: 1.05 !default;
$saf-distance-from-origin: 5em !default;
$saf-sunrise-arch: -15em !default;
$saf-enlarge-contract-multiple: 1.15 !default;
$saf-enlarge-contract-jump: 1em !default;
$saf-flip-scale: 1.2 !default;
